import React from 'react';
import Navbar from './Navbar';
const Header = () => {

  return (
    <header className="header">
      <Navbar/>
    </header>
  );
};

export default Header;
